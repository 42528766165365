<template>
  <div class="PracticeShowcase-bg Rec-page-bg">
    <div class="Rec-page-item">
      <p>{{ $t("CompanyList.topTitle") }}</p>
    </div>
    <div class="PraList" v-for="(item, index) in pralist" :key="index">
        <p class="PraList-title">
          {{ $t("COFITList." + item.title) }}
        </p>
        <div class="cnname-list">
          <p :class="['p1 p1_' + index]" style="margin-top: 20px">
            {{ item.cnname }}
          </p>
          <p :class="['p2 p2_' + index]" style="margin-bottom: 20px">
            {{ item.enname }}
          </p>
        </div>
    </div>
    <div class="Rec-page-item">
      <p>{{ $t("CompanyList.complist_tit1_2023") }}</p>
    </div>
    <RecPageCom :RecList="fewerCompany2023" />
    <RecPageCom :RecList="fewerOrganisation2023" />
    <RecPageCom :RecList="betweenCompany2023" />  
    <RecPageCom :RecList="betweenOrganisation2023" />
    <RecPageCom :RecList="moreCompany2023" />
    <RecPageCom :RecList="moreOrganisation2023" />
  </div>
</template>

<script>
import RecPageCom from "../components/RecPageCom.vue";
import fewerCompany2023 from "../js/Fewer";
import fewerOrganisation2023 from "../js/Fewer";
import betweenCompany2023 from "../js/Between";
import betweenOrganisation2023 from "../js/Between";
import moreCompany2023 from "../js/More";
import moreOrganisation2023 from "../js/More";
export default {
  components: { RecPageCom },
  mixins: [
    fewerOrganisation2023,
    betweenCompany2023,
    betweenOrganisation2023,
    moreCompany2023,
    moreOrganisation2023,
    fewerCompany2023
  ],
  data() {
    return {
      pralist: [
        {
          title: "co_tit3",
          cnname: "亞洲運動及體適能專業學院有限公司",
          enname: "Asian Academy for Sports & Fitness Professionals Limited",
        },
        {
          cnname: "啟德體育園",
          enname: "Kai Tak Sports Park",
        },
        {
          cnname: "Time Auction Limited",
          enname: "Time Auction Limited",
        },
        {
          title: "co_tit4",
          cnname: "adidas (adidas Sourcing Limited & adidas Hong Kong Limited)",
          enname: "adidas (adidas Sourcing Limited & adidas Hong Kong Limited)",
        },
        {
          cnname: "興勝創建控股有限公司",
          enname: "Hanison Construction Holdings Limited",
        },
        {
          cnname: "霍金路偉律師行",
          enname: "Hogan Lovells",
        },
        {
          cnname: "特斯拉汽車有限公司",
          enname: "Tesla Motors HK Limited",
        },

        {
          title: "co_tit5",
          cnname: "中華電力有限公司",
          enname: "CLP Power Hong Kong Limited",
        },
        {
          cnname: "星展銀行（香港）有限公司",
          enname: "DBS Bank (Hong Kong) Limited",
        },
        {
          cnname: "香港上海滙豐銀行",
          enname: "The Hongkong and Shanghai Banking Corporation Limited",
        }
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.Rec-page-bg {
  .Rec-page-item {
    margin: 30px;
    p {
      color: black;
      font-size: 17px;
      // font-weight: 600;
      font-family: "PingFang-Bold";
    }
  }
}
.PracticeShowcase-bg {
    margin-bottom: 50px;
    .Pra-item {
      margin: 30px;
      .p_item {
        color: black;
        font-size: 17px;
        font-family: "PingFang-Bold";
        margin-bottom: 40px;
      }
    }
    .PraList {
      .PraList-title {
        margin: 30px;
        display: inline;
        line-height: 60px;
        background-color: rgb(217, 217, 217);
        color: black;
      }
      width: 80%;
      margin-left: 10%;
    }
    :nth-child(3),
    :nth-child(4),
    :nth-child(6),
    :nth-child(7),
    :nth-child(8),
    :nth-child(10),
    :nth-child(11) {
      .PraList-title {
        display: none !important;
      }
    }
    :nth-child(4),
    :nth-child(8),
    :nth-child(11) {
      .cnname-list {
        border: 2px solid rgb(97, 96, 96);
      }
    }
  }
.cnname-list {
    min-height: 70px;
    border: 2px solid rgb(97, 96, 96);
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: column;
    .p1_2,
    .p1_3 {
      display: none;
    }
  }
</style>